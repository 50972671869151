import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./TablePlantYearEspecieStyle.css";

export class TablePlantEspecie extends Component {
  static contextType = PlantDataContext;

  handleRowClick = (especieClic) => {
    const { especie, setEspecie } = this.context;
    if (especie === especieClic) {
      setEspecie("");
    } else setEspecie(especieClic);
  };

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupar datos por especie y sumar `m_prima` y `produccion`
    const dataByEspecie = {};
    let totalMateriaPrima = 0;
    let totalProduccion = 0;

    filteredGeneralData.forEach((item) => {
      const key = item.especie;
      if (!dataByEspecie[key]) {
        dataByEspecie[key] = {
          especie: item.especie,
          mPrima: 0,
          produccion: 0,
        };
      }
      dataByEspecie[key].mPrima += item.m_prima;
      dataByEspecie[key].produccion += item.produccion;

      // Calcular totales
      totalMateriaPrima += item.m_prima;
      totalProduccion += item.produccion;
    });

    // Formatear datos para la tabla
    const data = Object.values(dataByEspecie).map((item) => ({
      especie: item.especie,
      mPrima: item.mPrima.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      produccion: item.produccion.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));

    // Ordenar alfabéticamente por especie
    data.sort((a, b) => a.especie.localeCompare(b.especie));

    return (
      <div className="container-table-planta-especie">
        <TableContainer className="table-planta-especie" component={Paper}>
          <div className="header-table-planta-especie">
            <div className="header-table-planta-especie">
              {region
                ? `Datos por especie de la Región ${region}`
                : "Datos por especie País"}
            </div>
          </div>
          <Table aria-label="plant table" className="table-info-planta-especie">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Especie
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Materia Prima
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Producción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.especie)}
                >
                  <TableCell align="center">{row.especie}</TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.mPrima}
                  </TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.produccion}
                  </TableCell>
                </TableRow>
              ))}
              {/* Fila de Totales */}
              <TableRow className="table-total-planta-especie no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-planta-especie">Total</strong>
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-especie">
                  {totalMateriaPrima.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-especie">
                  {totalProduccion.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TablePlantEspecie;
