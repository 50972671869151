import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant"; 
import "./TablePlantYearStyle.css";

export class TablePlantYear extends Component {
  static contextType = PlantDataContext;

  handleRowClick = (yearClic) => {
    const { year, setYear } = this.context;
    if (year === yearClic) {
      setYear("");
    } else setYear(yearClic);
  };

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupar datos por año y sumar `m_prima` y `produccion`
    const dataByYear = {};
    filteredGeneralData.forEach((item) => {
      const key = item.year;
      if (!dataByYear[key]) {
        dataByYear[key] = {
          year: item.year,
          mPrima: 0,
          produccion: 0,
        };
      }
      dataByYear[key].mPrima += item.m_prima;
      dataByYear[key].produccion += item.produccion;
    });

    // Formatear datos para la tabla
    const data = Object.values(dataByYear).map((item) => ({
      year: item.year,
      mPrima: item.mPrima.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      produccion: item.produccion.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));

    // Ordenar por año descendente
    data.sort((a, b) => b.year - a.year);

    return (
      <div className="container-table-region-year">
        <TableContainer className="table-region-year" component={Paper}>
          <div className="header-table-region-year">
            <div className="header-table-region-year">
              {region ? `Datos por año de la Región ${region}` : "Datos por año País"}
            </div>
          </div>
          <Table aria-label="plant table" className="table-info-region-year">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Año
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Materia Prima
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Producción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.year)}
                >
                  <TableCell align="center">{row.year}</TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.mPrima}
                  </TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.produccion}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TablePlantYear;
