import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import { isMobile } from "react-device-detect";
import "./ChartLineYearPlantStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartLineYearPlant extends Component {
  static contextType = PlantDataContext;

  getHeaderTitle(region) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "") return "Datos de Materia Prima y Producción por Año, País";
    if (region !== "" && region !== 999 && region !== 99)
      return `Datos de Materia Prima y Producción por Año en la Región ${regionName}`;
    if (region === 999) return "Datos de Materia Prima y Producción por Año en el Extranjero";
    if (region === 99)
      return "Datos de Materia Prima y Producción por Año en Lanchas Transportadoras";

    return "Sin información disponible";
  }

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupar datos por año y sumar `m_prima` y `produccion`
    const dataByYear = {};
    filteredGeneralData.forEach((item) => {
      const key = item.year;
      if (!dataByYear[key]) {
        dataByYear[key] = { mPrima: 0, produccion: 0 };
      }
      dataByYear[key].mPrima += item.m_prima;
      dataByYear[key].produccion += item.produccion;
    });

    // Crear las categorías de años y los datos para las series
    const years = Object.keys(dataByYear).sort((a, b) => a - b);
    const mPrimaData = years.map((year) => dataByYear[year].mPrima);
    const produccionData = years.map((year) => dataByYear[year].produccion);

    const formatChileanNumber = (value) => {
      if (isNaN(value)) return 0;
      const roundedValue = Math.round(value * 10) / 10;
      const parts = roundedValue.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    };

    const option = {
      color: ["#5C7BD9", "#FFA07A"], // Colores para las líneas
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${formatChileanNumber(param.value)}<br/>`;
          });
          return tooltipText;
        },
      },
      legend: {
        data: ["Materia Prima", "Producción"],
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: isMobile ? 90 : 0,
        },
        data: years,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatChileanNumber,
        },
      },
      series: [
        {
          name: "Materia Prima",
          type: "line",
          data: mPrimaData,
        },
        {
          name: "Producción",
          type: "line",
          data: produccionData,
        },
      ],
    };

    return (
      <div className="container-chart-general-line-resource-year">
        <div className="title-text-chart-year-year-resource">
          {this.getHeaderTitle(region)}
        </div>
        <ReactECharts
          className="chart-general-resource-year"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: "87%", width: "100%", minHeight: "300px" }}
        />
      </div>
    );
  }
}

export default ChartLineYearPlant;
