
import {
faHouse,
faGlobe,
faFishFins,
faMapLocationDot,
faShip,
faUserGroup,
faIndustry,
faFilter
} from '@fortawesome/free-solid-svg-icons'
const ListMenu = [
  { text: "Home", icon: faHouse  , path:"" },
  { text: "Regiones", icon: faGlobe, path:"region" },
  { text: "Recursos", icon:faFishFins, path:"recursos" }, 
  { text: "Zona de Pesca", icon: faMapLocationDot, path:"zonaPesca"  },
  { text: "Flota", icon: faShip , path:"flota"  },
  { text: "Busqueda personalizada", icon: faFilter , path:"filter"  },
  { text: "Permisos", icon: faUserGroup, path:"permisos" },
  { text: "Plantas", icon:faIndustry, path:"plantas" },
];


export default ListMenu;