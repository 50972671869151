// DataProviderHome.js
import React, { useState, useEffect, useContext } from "react";
import { GlobalDataContext } from "./DataProvider"; // Importa el contexto, no el componente

export const PlantDataContext = React.createContext(); // Renombra este contexto para evitar colisiones

const DataProviderPlant = (props) => {
  const [year, setYear] = useState("");
  const [region, setRegion] = useState("");
  const [planta, setPlanta] = useState("");
  const [linea, setLinea] = useState("");
  const [especie, setEspecie] = useState("");
  const [reproceso, setReproceso] = useState("");
  const [generalData, setGeneralData] = useState([]);
  const [filteredGeneralData, setFilteredGeneralData] = useState([]);
  const { setIsLoader } = useContext(GlobalDataContext); // Usa el contexto correcto
    const [isVisibleIA, setIsVisibleIA] = useState(false);
  const [iaState, setIaState] = useState(false); 
  useEffect(() => {
    setIsLoader(true);
    const fetchData = async () => {
      console.log(`${process.env.REACT_APP_BACKEND_SERVER}`);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_SERVER}/plantas`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
        setGeneralData(jsonData);
        setFilteredGeneralData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoader(false);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filteredData = [...generalData];

      if (region !== "") {
        filteredData = filteredData.filter(
          (item) => item.nombre_region === region
        );
      }

      if (year!== "") {
        filteredData = filteredData.filter((item) => item.year === year);
      }
      if (especie!== "") {
        filteredData = filteredData.filter((item) => item.especie === especie);
      }
      if (reproceso!== "") {
        filteredData = filteredData.filter((item) => item.reproceso === reproceso);
      }
        setFilteredGeneralData(filteredData);
      
    };

    applyFilters();
    // eslint-disable-next-line
  }, [region, generalData, year, especie, reproceso]);

  return (
    <PlantDataContext.Provider
      value={{
        region,
        setRegion,
        year,
        setYear,
        planta,
        setPlanta,
        linea,
        setLinea,
        especie,
        setEspecie,
        reproceso,
        setReproceso,
        isVisibleIA, 
        setIsVisibleIA,
        generalData,
        filteredGeneralData,
        iaState, 
        setIaState,
      }}
    >
      {props.children}
    </PlantDataContext.Provider>
  );
};

export default DataProviderPlant;
