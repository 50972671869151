import React, { useContext } from "react";

import { GlobalDataContext } from "../../contexts/DataProvider";
import { ZoneDataContext } from "../../contexts/DataProviderZone";
import "./ZoneScreenStyle.css";
import FloatingMenuZone from "../../components/ZoneScreenComponent/FloatingMenuZone/FloatingMenuZone";
import Loading from "../../components/Loading/Loading";
import MapZone from "../../components/ZoneScreenComponent/MapZone/MapZone";
import TableFlotaZoneyear from "../../components/ZoneScreenComponent/TablesZone/TableFlotaZoneYear/TableFlotaZoneYear";
import TableFlotaZoneEspecie from "../../components/ZoneScreenComponent/TablesZone/TableFlotaZoneEspecie/TableFlotaZoneEspecie";
import TableFlotaZoneCaleta from "../../components/ZoneScreenComponent/TablesZone/TableFlotaZoneCaleta/TableFlotaZoneCaleta";
import TableFlotaZoneFlota from "../../components/ZoneScreenComponent/TablesZone/TableFlotaZoneFlota/TableFlotaZoneFlota";
import TableFlotaEmbarcacionesZone from "../../components/ZoneScreenComponent/TablesZone/TableFlotaEmbarcacionesZone/TableFlotaEmbarcacionesZone";
import TablePescadoresCategoriaAgrupadasZone from "../../components/ZoneScreenComponent/TablesZone/TablePescadoresCategoriaAgrupadasZone/TablePescadoresCategoriaAgrupadasZone";
import TablePescadoresZone from "../../components/ZoneScreenComponent/TablesZone/TablePescadoresZone/TablePescadoresZone";
import ChartCircleCaletaZone from "../../components/ZoneScreenComponent/ChartsZone/ChartCircleCaletaZone/ChartCircleCaletaZone";
import ChartCircleEspeciesZone from "../../components/ZoneScreenComponent/ChartsZone/ChartCircleEspeciesZone/ChartCircleEspeciesZone";
import ChartCircleFlotaCategoriaZoneDesemb from "../../components/ZoneScreenComponent/ChartsZone/ChartCircleFlotaCategoriaZoneDesemb/ChartCircleFlotaCategoriaZoneDesemb";
import ChartCircleFlotaCategoriaZoneEmb from "../../components/ZoneScreenComponent/ChartsZone/ChartCircleFlotaCategoriaZoneEmb/ChartCircleFlotaCategoriaZoneEmb";
import ChartCircleFlotaZone from "../../components/ZoneScreenComponent/ChartsZone/ChartCircleFlotaZone/ChartCircleFlotaZone";
import ChartLineFlotaZone from "../../components/ZoneScreenComponent/ChartsZone/ChartLineFlotaZone/ChartLineFlotaZone";
import ChartLinePescadoresCategoriasZone from "../../components/ZoneScreenComponent/ChartsZone/ChartLinePescadoresCategoriasZone/ChartLinePescadoresCategoriasZone";
import ChartLineYearZone from "../../components/ZoneScreenComponent/ChartsZone/ChartLineYearZone/ChartLineYearZone";

export default function ZoneScreen() {
  // Usar el hook useContext para acceder a DataContext
  const { isLoader } = useContext(GlobalDataContext);
  const { zone } = useContext(ZoneDataContext);
  return (
    <div className="container-zone-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <>
          <div className="container-zone-select-0">
            {zone === "" ? (
              <MapZone />
            ) : (
              <>
                <FloatingMenuZone />
                <TableFlotaZoneyear />
                <TableFlotaZoneEspecie />
                <TableFlotaZoneCaleta />
                <TableFlotaZoneFlota />
                <TableFlotaEmbarcacionesZone />
                <TablePescadoresCategoriaAgrupadasZone/>
                <TablePescadoresZone/>
                <ChartCircleCaletaZone/>
                <ChartCircleEspeciesZone/>
                <ChartCircleFlotaCategoriaZoneDesemb/>
                <ChartCircleFlotaCategoriaZoneEmb/>
                <ChartCircleFlotaZone/>
                <ChartLineFlotaZone/>
                <ChartLinePescadoresCategoriasZone/>
                <ChartLineYearZone/>
                
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
