import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { PlantDataContext } from "../../../../contexts/DataProviderPlant";
import "./TablePlantReprocessStyle.css";

export class TablePlantReprocess extends Component {
  static contextType = PlantDataContext;

  handleRowClick = (reprocesoClic) => {
    let dataClic= reprocesoClic ==="SI"  ?  "R": reprocesoClic ==="NO" ?"F"  : "DESCONOCIDO"
    const { reproceso, setReproceso } = this.context;
    if (reproceso === dataClic) {
      setReproceso("");
    } else setReproceso(dataClic);
  };

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupar datos por reproceso y sumar `m_prima` y `produccion`
    const dataByReproceso = {};
    let totalMateriaPrima = 0;
    let totalProduccion = 0;

    filteredGeneralData.forEach((item) => {
      const key = item.reproceso;
      if (!dataByReproceso[key]) {
        dataByReproceso[key] = {
          reproceso: key === "R" ? "SI" : key === "F" ? "NO" : "DESCONOCIDO",
          mPrima: 0,
          produccion: 0,
        };
      }
      dataByReproceso[key].mPrima += item.m_prima;
      dataByReproceso[key].produccion += item.produccion;

      // Calcular totales
      totalMateriaPrima += item.m_prima;
      totalProduccion += item.produccion;
    });

    // Formatear datos para la tabla
    const data = Object.values(dataByReproceso).map((item) => ({
      reproceso: item.reproceso,
      mPrima: item.mPrima.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      produccion: item.produccion.toLocaleString("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));

    // Ordenar alfabéticamente por reproceso
    data.sort((a, b) => a.reproceso.localeCompare(b.reproceso));

    return (
      <div className="container-table-planta-reproceso">
        <TableContainer className="table-planta-reproceso" component={Paper}>
          <div className="header-table-planta-reproceso">
            <div className="header-table-planta-reproceso">
              {region
                ? `Datos por reproceso de la Región ${region}`
                : "Datos por reproceso País"}
            </div>
          </div>
          <Table aria-label="plant table" className="table-info-planta-reproceso">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Reproceso
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Materia Prima
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Producción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.reproceso)}
                >
                  <TableCell align="center">{row.reproceso}</TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.mPrima}
                  </TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.produccion}
                  </TableCell>
                </TableRow>
              ))}
              {/* Fila de Totales */}
              <TableRow className="table-total-planta-reproceso no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-planta-reproceso">Total</strong>
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-reproceso">
                  {totalMateriaPrima.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell align="right" className="table-total-text-planta-reproceso">
                  {totalProduccion.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TablePlantReprocess;
