import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DrawerMenu from "../components/DrawerMenu/DrawerMenu.jsx";
import NavbarMain from "../components/NavbarMain/NavbarMain.jsx";
import Login from "../components/Login/Login.jsx";
import NoFoundScreen from "../screens/NoFoundScreen";
import "./AppRouter.css";
import RegionScreen from "../screens/RegionScreen/RegionScreen.jsx";
import HomeScreen from "../screens/HomeScreen/HomeScreen.jsx";
import ResourcesScreen from "../screens/ResourcesScreen/ResourcesScreen.jsx";
import DataProviderHome from "../contexts/DataProviderHome.js";
import RegionDataContext from "../contexts/DataProviderRegion.js";
import DataProvider, { GlobalDataContext } from "../contexts/DataProvider.js";
import PageUnderConstruction from "../screens/PageUnderConstruction/PageUnderConstruction.jsx";
import Footer from "../components/Footer/Footer.jsx";
import AccesDeniedScreen from "../screens/AccesDeniedScreen/AccesDeniedScreen.jsx";
import ResourceDataContext from "../contexts/DataProviderResource.js";
import DataProviderflota from "../contexts/DataProviderFlota.js"; // Importa el Provider correcto
import FlotaScreen from "../screens/FlotaScreen/FlotaScreen.jsx";
import DataProviderFilter from "../contexts/DataProviderFilter.js";
import FilterScreen from "../screens/FilterScreen/FilterScreen.jsx";
import PlantScreen from "../screens/PlantScreen/PlantScreen.jsx";
import DataProviderZone from "../contexts/DataProviderZone.js";
import ZoneScreen from "../screens/ZoneScreen/ZoneScreen.jsx";
import DataProviderPlant from "../contexts/DataProviderPlant.js";

export const AppRouter = () => {
  const { isLoader } = useContext(GlobalDataContext);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    // Implementar lógica de autenticación si es necesario
  }, []);

  return (
    <BrowserRouter>
      <DataProvider>
        <NavbarMain />
        <DrawerMenu />
        <Login isAuth={isAuth} setIsAuth={setIsAuth} />
        <div className="view-container">
          <Routes>
            {/* Ruta de inicio siempre disponible */}
            <Route
              path="/"
              element={
                <DataProviderHome>
                  <HomeScreen />
                </DataProviderHome>
              }
            />
            {/* Rutas protegidas solo para usuarios autenticados */}
            {isAuth ? (
              <>
                <Route
                  path="/region"
                  element={
                    <RegionDataContext>
                      <RegionScreen />
                    </RegionDataContext>
                  }
                />
                <Route
                  path="/recursos"
                  element={
                    <ResourceDataContext>
                      <ResourcesScreen />
                    </ResourceDataContext>
                  }
                />
                <Route
                  path="/flota"
                  element={
                    <DataProviderflota>
                      {" "}
                      {/* Usa el Provider correcto */}
                      <FlotaScreen />
                    </DataProviderflota>
                  }
                />
                <Route
                  path="/filter"
                  element={
                    <DataProviderFilter>
                      {" "}
                      {/* Usa el Provider correcto */}
                      <FilterScreen />
                    </DataProviderFilter>
                  }
                />
                <Route
                  path="/zonaPesca"
                  element={
                    <DataProviderZone>
                      {" "}
                      {/* Usa el Provider correcto */}
                      <ZoneScreen/>
                    </DataProviderZone>
                  }
                />
                            <Route
                  path="/plantas"
                  element={
                    <DataProviderPlant>
                      {" "}
                      {/* Usa el Provider correcto */}
                      <PlantScreen/>
                    </DataProviderPlant>
                  }
                />

                <Route path="/permisos" element={<PageUnderConstruction />} />

              </>
            ) : (
              // Redirigir a la página de inicio si no está autenticado
              <Route path="/*" element={<AccesDeniedScreen />} />
            )}
            {/* Ruta para manejar 404 */}
            <Route path="/*" element={<NoFoundScreen />} />
          </Routes>
        </div>
        {/* Mostrar el footer solo si isLoader es false */}
        {!isLoader && <Footer />}
      </DataProvider>
    </BrowserRouter>
  );
};
