import React, { useContext } from "react";
import "./FloatingMenuZoneStyle.css"; // Asegúrate de enlazar el archivo CSS donde defines el estilo del menú
import { ZoneDataContext } from "../../../contexts/DataProviderZone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const FloatingMenuZone = () => {
  // Accede al contexto usando el hook useContext
  const {
    region,
    setRegion,
    zone,
    setZone,
    flota,
    setFlota,
    especie,
    setEspecie,
    caleta,
    setCaleta,
    year,
    setYear,
    eslora,
    setEslora,
    setIsVisibleIA,
  } = useContext(ZoneDataContext);

  // Función para manejar la eliminación de filtros
  const handleDelete = (filter) => {
    switch (filter) {
      case "zone":
        setZone("");
        setRegion("");
        setFlota("");
        setEspecie("");
        setCaleta("");
        setYear("");
        setEslora("");
        setIsVisibleIA(false); // Se oculta IA al borrar región
        break;
      case "flota":
        setFlota("");
        setIsVisibleIA(false); // Se oculta IA al borrar flota
        break;
        case "region":
          setRegion("");
          setIsVisibleIA(false); // Se oculta IA al borrar flota
          break;  
      case "especie":
        setEspecie("");
        setIsVisibleIA(false); // Se oculta IA al borrar especie
        break;
      case "caleta":
        setCaleta("");
        setIsVisibleIA(false); // Se oculta IA al borrar caleta
        break;
      case "year":
        setYear("");
        setIsVisibleIA(false); // Se oculta IA al borrar año
        break;
      case "eslora":
        setEslora("");
        setIsVisibleIA(false); // Se oculta IA al borrar eslora
        break;
      default:
        break;
    }
  };

  return (
    <div className="floating-menu">
      <div className="container-title-menu-floating">Filtros Aplicados</div>

      <table>
        <tbody>
          {region !== "" && (
            <tr>
              <td>
                Zona:{" "}
                {region === 9999
                  ? "Recolectores"
                  : region === 0
                  ? "Zona sin Identificar"
                  : region === 160
                  ? "Aguas Internacionales"
                  : region}
              </td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("region")}
                />
              </td>
            </tr>
          )}
          {zone !== "" && (
            <tr>
              <td>
                <td>Zona: {zone === '9999'
                  ? "Recolectores"
                  : zone === '0'
                  ? "Zona sin Identificar"
                  : zone === '160'
                  ? "Aguas Internacionales"
                  : zone}</td>
              </td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("zone")}
                />
              </td>
            </tr>
          )}
          {flota !== "" && (
            <tr>
              <td>Flota: {flota}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("flota")}
                />
              </td>
            </tr>
          )}
          {especie !== "" && (
            <tr>
              <td>Especie: {especie}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("especie")}
                />
              </td>
            </tr>
          )}
          {caleta !== "" && (
            <tr>
              <td>Caleta: {caleta}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("caleta")}
                />
              </td>
            </tr>
          )}
          {year !== "" && (
            <tr>
              <td>Año: {year}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("year")}
                />
              </td>
            </tr>
          )}
          {eslora !== "" && (
            <tr>
              <td>
                Categoria: {eslora === "NO APLICA" ? "RECOLECTOR" : eslora}
              </td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("eslora")}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FloatingMenuZone;
