import React, { useContext } from "react";
import "./FilterScreenStyle.css";
import { GlobalDataContext } from "../../contexts/DataProvider";
import Loading from "../../components/Loading/Loading";
import FilterCarousel from "../../components/FilterScreenComponent/FilterCarousel/FilterCarousel";
export default function FilterScreen() {
  // Usar el hook useContext para acceder a DataContext
  const { isLoader } = useContext(GlobalDataContext);

  return (
    <div className="container-region-select-screens">
      {isLoader ? (
        <Loading/>
      ) : (
        <>
          <div className="container-region-select-0">
             <FilterCarousel/>
          </div>
        </>
      )}
    </div>
  );
}
