import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ZoneDataContext } from '../../../../contexts/DataProviderZone';
import './TableFlotaZoneFlotaStyle.css';
export class TableFlotaZoneFlota extends Component {
  static contextType = ZoneDataContext;

  handleRowClick = (flotaClic) => {
    const { flota, setFlota } = this.context;
    if (flota=== flotaClic) {
      setFlota("");
    } else setFlota(flotaClic);
  };

  render() {
    const { filteredGeneralData, zone, flota } = this.context;

    // Mapa de las zonas

    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }




    // Inicializar un objeto para almacenar el desembarque por flota
    const desembarquePorFlota = {};

    // Calcular el total de desembarque para cada flota
    filteredGeneralData.forEach(item => {
      if (!desembarquePorFlota[item.flota]) {
        desembarquePorFlota[item.flota] = 0;
      }
      desembarquePorFlota[item.flota] += item.desembarque;
    });

    // Calcular el total general de desembarque
    const totalDesembarque = Object.values(desembarquePorFlota).reduce((sum, value) => sum + value, 0);

    // Preparar datos para la tabla y calcular porcentajes
    const data = Object.keys(desembarquePorFlota).map(flota => {
      const desembarque = desembarquePorFlota[flota];
      const percentage = (desembarque / totalDesembarque) * 100;
      return {
        flota,
        desembarque: desembarque.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        percentage: isNaN(percentage) ? '0' : percentage.toFixed(1)
      };
    });

    return (

        <TableContainer className='table-zona-flota' component={Paper}>
          <div className='header-table'>
            {zone === "" && flota === ""
      ? `Desembarque por flota País`
      : zone === "" && flota !== ""
      ? `Desembarque por flota País ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`
      : zone !== "" &&
        zone !== '160' &&
        zone !== '9999' &&
        zone !== '0' &&
        flota === ""
      ? `Desembarque por flota de la Zona ${zone}`
      : zone !== "" && zone === '160' && flota === ""
      ? `Desembarque por flota Zona Pesca Aguas Internacionales`
      : zone !== "" && zone === '9999' && flota === ""
      ? `Desembarque por flota Zona Pesca de Recolectores`
      : zone !== "" && zone === '0' && flota === ""
      ? `Desembarque por flota Zona Pesca Sin Identificar`
      : zone !== "" &&
        zone !== 999 &&
        zone !== 99 &&
        flota !== ""
      ? `Desembarque por flota ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Zona ${zone}`
      : zone !== "" && zone === 999 && flota !== ""
      ? `Desembarque por flota ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero`
      : zone !== "" && zone === 99 && flota !== ""
      ? `Desembarque por flota ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras`
      : `Sin información disponible`}
          </div>
          <Table aria-label="flota table" className='table-info-zona-flota'>
            <TableHead className="no-hover-title">
              <TableRow className='title-column'>
                <TableCell className='text-title-column' align="center">Categoria</TableCell>
                <TableCell  className='text-title-column' align="center">Toneladas</TableCell>
                <TableCell className='text-title-column'  align="center">Porcentaje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow 
                key={index}
                onClick={() => this.handleRowClick(row.flota)}
                >
                  <TableCell component="th" scope="row">
                    {row.flota}
                  </TableCell>
                  <TableCell align="right" className='text-data-table'>{row.desembarque}</TableCell>
                  <TableCell align="right">{row.percentage}%</TableCell>
                </TableRow>
              ))}
              {/* Totales */}
              <TableRow className='table-total-zona-flota  no-hover'>
                <TableCell colSpan={1} align="right"><strong className='table-total-text'>Total</strong></TableCell>
                <TableCell align="right" className='table-total-text  text-data-table'>
                  {totalDesembarque.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell align="right" className='table-total-text'>100%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

    );
  }
}

export default TableFlotaZoneFlota;
