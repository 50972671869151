import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { ZoneDataContext } from "../../../../contexts/DataProviderZone";
import "./ChartCircleFlotaCategoriaZoneDesembStyle.css";

export class ChartCircleFlotaCategoriaZoneDesemb extends Component {
  static contextType = ZoneDataContext;
  getHeaderTitle(zone, flota) {
    return zone === "" && flota === ""
      ? `Desembarque por categoria país`
      : zone === "" && flota !== ""
      ? `Desembarque por categoria ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`
      : zone !== "" &&
        zone !== '160' &&
        zone !== '9999' &&
        zone !== '0' &&
        flota === ""
      ? `Desembarque por categoria de la Zona ${zone}`
      : zone !== "" && zone === '160' && flota === ""
      ? `Desembarque por categoria Zona pesca aguas internacionales`
      : zone !== "" && zone === '9999' && flota === ""
      ? `Desembarque por categoria Zona Pesca de recolectores`
      : zone !== "" && zone === '0' && flota === ""
      ? `Desembarque por categoria Zona Pesca sin Identificar`
      : zone !== "" &&
        zone !== 999 &&
        zone !== 99 &&
        flota !== ""
      ? `Desembarque por categoria ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Zona ${zone}`
      : zone !== "" && zone === 999 && flota !== ""
      ? `Desembarque por categoria ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero`
      : zone !== "" && zone === 99 && flota !== ""
      ? `Desembarque por categoria ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras`
      : `Sin información disponible`;
  }
  handleChartClick = (params) => {
    const { setEslora, eslora } = this.context;

    // Reemplaza "artesanal" si está presente en el nombre
    let esloraCategoria = params.name.includes("ART.")
      ? params.name.replace("ART.", "").trim() // Elimina "artesanal" y quita espacios
      : params.name === "RECOLECTOR"
      ? "NO APLICA"
      : params.name;

    if (esloraCategoria !== eslora) {
      setEslora(esloraCategoria);
    } else {
      setEslora("");
    }
  };

  render() {
    const { filteredGeneralData, zone,  flota, eslora } = this.context;

    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por categoría de eslora
    const desembarquePorEslora = {};

    // Calcular el total de desembarque para cada categoría de eslora
    filteredGeneralData.forEach((item) => {
      let esloraCategoria =
        item.categoria_eslora === "NO APLICA"
          ? "RECOLECTOR"
          : item.flota === "ARTESANAL"
          ? `ART. ${item.categoria_eslora}`
          : item.categoria_eslora; // Reemplaza "NO APLICA" por "RECOLECTOR NO APLICA"
      if (!desembarquePorEslora[esloraCategoria]) {
        desembarquePorEslora[esloraCategoria] = 0;
      }
      desembarquePorEslora[esloraCategoria] += item.desembarque;
    });

    // Preparar datos para el gráfico
    const data = Object.keys(desembarquePorEslora).map((eslora) => ({
      name: eslora,
      value: desembarquePorEslora[eslora],
    })).sort((a, b) => b.value - a.value); // Ordenar de mayor a menor;

    const option = {
      color: [  "#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463", // Verde intenso
        "ORANGE",
        "#2980B9", // Azul intenso
        "#C70039", // Rojo intenso
        "#5DADE2", // Azul cielo fuerte
        "#900C3F", // Vino
        "#117864", // Verde esmeralda
        "#581845", // Púrpura oscuro
        "#FF5733", // Naranja fuerte
        "#FFC300", // Amarillo vibrante
        "#DAF7A6", // Verde claro vibrante
        "#1F618D", // Azul oscuro
        "#2874A6", // Azul mediano
        "#D98880", // Rojo coral
        "#AF7AC5", // Púrpura vibrante
        "#D4AC0D", // Dorado oscuro
        "#1ABC9C", // Verde azulado
        "#3498DB", // Azul medio
        "#E74C3C", // Rojo fuego
        "#2E86C1", // Azul medio-oscuro
        "#A93226", // Rojo oscuro
        "#F39C12", // Naranja vibrante
        "#C0392B", // Rojo granate
        "#27AE60", // Verde profundo
        "#F4D03F", // Amarillo fuerte
        ],

      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false, // Oculta la leyenda
      },
      series: [
        {
          name: "Desembarque",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-flota-region-desemb">
        <div className="title-text-chart-categoria-desemb">
        {this.getHeaderTitle(zone, flota, eslora)}
        </div>
        <ReactECharts
          className="chart-general-flota-region-desemb"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "88%", width: "100%" }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleFlotaCategoriaZoneDesemb;
