import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ZoneDataContext } from "../../../../contexts/DataProviderZone"; 
import "./TableFlotaEmbarcacionesZoneStyle.css";


export class TableFlotaEmbarcacionesZone extends Component {
  static contextType = ZoneDataContext;
  handleRowClick = (esloraClic) => {
    const { eslora, setEslora } = this.context;
    if (eslora === esloraClic) {
      setEslora("");
    } else  setEslora(esloraClic);
  };
  getHeaderTitle(zone, flota) {
    return zone === "" && flota === ""
      ? `Operación de embarcaciones por flota y categoría país`
      : zone === "" && flota !== ""
      ? `Operación de embarcaciones por flota y categoría país ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`
      : zone !== "" &&
        zone !== '160' &&
        zone !== '9999' &&
        zone !== '0' &&
        flota === ""
      ? `Operación de embarcaciones por flota y categoría de la Zona ${zone}`
      : zone !== "" && zone === '160' && flota === ""
      ? `Operación de embarcaciones por flota y categoría Zona pesca aguas internacionales`
      : zone !== "" && zone === '9999' && flota === ""
      ? `Operación de embarcaciones por flota y categoría Zona Pesca de recolectores`
      : zone !== "" && zone === '0' && flota === ""
      ? `Operación de embarcaciones por flota y categoría Zona Pesca sin Identificar`
      : zone !== "" &&
        zone !== 999 &&
        zone !== 99 &&
        flota !== ""
      ? `Operación de embarcaciones por flota y categoría ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Zona ${zone}`
      : zone !== "" && zone === 999 && flota !== ""
      ? `Operación de embarcaciones por flota y categoría ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero`
      : zone !== "" && zone === 99 && flota !== ""
      ? `Operación de embarcaciones por flota y categoría ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras`
      : `Sin información disponible`;
  }
  
  calculateTotals(filteredFlotaData, filteredGeneralData) {
    const conteoPorFlotaYCategoria = {};
    const desembarquesPorFlotaYCategoria = {};
    let totalDesembarques = 0;
    let totalEmbarcaciones = 0;
  
    // Agrupar los datos por flota, rpa_embarcacion, y categoria_eslora
    const uniqueEmbarcaciones = new Set();
  
    filteredFlotaData.forEach((item) => {
      const key = `${item.flota}_${item.rpa_embarcacion}_${item.categoria_eslora}`;
  
      // Solo agregar si es una combinación única de flota, embarcación y categoría
      if (!uniqueEmbarcaciones.has(key)) {
        uniqueEmbarcaciones.add(key);
  
        const keyFlotaCategoria = `${item.flota}_${item.categoria_eslora}`;
        if (!conteoPorFlotaYCategoria[keyFlotaCategoria]) {
          conteoPorFlotaYCategoria[keyFlotaCategoria] = {
            flota: item.flota,
            categoria_eslora: item.categoria_eslora,
            conteoEmbarcaciones: 0,
          };
        }
        conteoPorFlotaYCategoria[keyFlotaCategoria].conteoEmbarcaciones += 1;
  
        // Solo sumar al total si la flota no es "RECOLECTOR"
        if (item.flota !== "RECOLECTOR") {
          totalEmbarcaciones += 1;
        }
      }
    });
  
    // Sumar desembarques
    filteredGeneralData.forEach((item) => {
      const key = `${item.flota}_${item.categoria_eslora}`;
      if (!desembarquesPorFlotaYCategoria[key]) {
        desembarquesPorFlotaYCategoria[key] = {
          flota: item.flota,
          categoria_eslora: item.categoria_eslora,
          desembarqueTotal: 0,
        };
      }
      desembarquesPorFlotaYCategoria[key].desembarqueTotal += item.desembarque;
      totalDesembarques += item.desembarque;
    });
  
    // Combinar datos de embarcaciones y desembarques
    const data = Object.values(conteoPorFlotaYCategoria).map((item) => {
      const desembarque =
        desembarquesPorFlotaYCategoria[`${item.flota}_${item.categoria_eslora}`]
          ?.desembarqueTotal || 0;
      let conteoEmbarcaciones = item.conteoEmbarcaciones;
      let percentageEmbarcaciones = (conteoEmbarcaciones / totalEmbarcaciones) * 100;
  
      // Si la flota es "Recolectores", el conteo y el porcentaje de embarcaciones deben ser 0
      if (item.flota === "RECOLECTOR") {
        conteoEmbarcaciones = 0;
        percentageEmbarcaciones = 0;
      }
  
      const percentageDesembarques = (desembarque / totalDesembarques) * 100;
  
      return {
        flota: item.flota,
        categoria_eslora: item.categoria_eslora,
        conteoEmbarcaciones: conteoEmbarcaciones.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        desembarqueTotal: desembarque.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percentageEmbarcaciones: isNaN(percentageEmbarcaciones)
          ? "0"
          : percentageEmbarcaciones.toFixed(1),
        percentageDesembarques: isNaN(percentageDesembarques)
          ? "0"
          : percentageDesembarques.toFixed(1),
        desembarqueTotalValue: desembarque, // Guardar valor real de desembarque
      };
    });
  
    // Filtrar recolectores sin desembarques
    const dataFiltrada = data.filter(
      (item) => !(item.flota === "RECOLECTOR" && item.desembarqueTotalValue === 0)
    );
  
    // Definir el orden predefinido
    const ordenPredefinido = {
      "ARTESANAL_IGUAL O MENOR A 8": 0,
      "ARTESANAL_MAYOR A 8 HASTA 12": 1,
      "ARTESANAL_MAYOR A 12 HASTA 15": 2,
      "ARTESANAL_MAYOR A 15 HASTA 18": 3,
      INDUSTRIAL_INDUSTRIAL: 4,
      FABRICA_FABRICA: 5,
      "RECOLECTOR_NO APLICA)": 6,
    };
  
    const dataOrdenada = dataFiltrada.sort((a, b) => {
      const keyA = `${a.flota}_${a.categoria_eslora}`;
      const keyB = `${b.flota}_${b.categoria_eslora}`;
      const indexA =
        ordenPredefinido[keyA] !== undefined ? ordenPredefinido[keyA] : 999;
      const indexB =
        ordenPredefinido[keyB] !== undefined ? ordenPredefinido[keyB] : 999;
      return indexA - indexB;
    });
  
    return { data: dataOrdenada, totalEmbarcaciones, totalDesembarques };
  }
  

 
  render() {
    const { filteredZoneBoatsData, filteredGeneralData, zone, flota } =
      this.context;


    if (!filteredZoneBoatsData || filteredZoneBoatsData.length === 0) {
      return (
        <div className="container-table-region-embarcacion">
          <TableContainer
            className="table-region-embarcacion"
            component={Paper}
          >
            <div className="header-table-region-embarcacion">
              <div className="header-table">
                {this.getHeaderTitle(zone, flota)}
              </div>
            </div>
            <Table
              aria-label="flota table"
              className="table-info-region-embarcacion"
            >
              <TableHead>
                <TableRow className="title-column">
                  <TableCell className="text-title-column" align="left">
                    Información
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left">{"Sin embarcaciones"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }

    const { data, totalEmbarcaciones, totalDesembarques } =
      this.calculateTotals(filteredZoneBoatsData, filteredGeneralData);

    return (

        <TableContainer className="table-region-embarcacion" component={Paper}>
          <div className="header-table-region-embarcacion">
            <div className="header-table">
              {this.getHeaderTitle(zone, flota)}
            </div>
          </div>
          <Table
            aria-label="flota table"
            className="table-info-region-embarcacion"
          >
            <TableHead>
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Flota
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Categoría
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Número de Embarcaciones
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  % Embarcaciones
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Desembarques (tons)
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  % Desembarques
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((row, index) => (
                <TableRow
                key={index}
                onClick={() => this.handleRowClick(row.categoria_eslora)}
                >
                  <TableCell align="left">{row.flota}</TableCell>
                  <TableCell align="left">{row.categoria_eslora}</TableCell>
                  <TableCell align="right">{row.conteoEmbarcaciones}</TableCell>
                  <TableCell align="right">
                    {row.percentageEmbarcaciones}%
                  </TableCell>
                  <TableCell align="right">{row.desembarqueTotal}</TableCell>
                  <TableCell align="right">
                    {row.percentageDesembarques}%
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className="table-total-region-embarcacion  no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-region-embarcacion">
                    Total
                  </strong>
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-embarcacion"
                ></TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-embarcacion"
                >
                  {totalEmbarcaciones.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-embarcacion"
                >
                  100%
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-embarcacion"
                >
                  {totalDesembarques.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-embarcacion"
                >
                  100%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        
     
    );
  }
}

export default TableFlotaEmbarcacionesZone;