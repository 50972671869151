import React, { useState, useEffect, useContext } from 'react';
import { GlobalDataContext } from './DataProvider';  // Importa el contexto, no el componente

export const FilterDataContext = React.createContext();  // Renombra este contexto para evitar colisiones

const DataProviderFilter= (props) => {
  const [region, setRegion] = useState('');
  const [caleta, setCaleta] = useState('');
  const [flota, setFlota] = useState('');   
  const [generalData, setGeneralData] = useState([]);
  const [filteredGeneralData, setFilteredGeneralData] = useState([]); 
  const [flotaData, setFlotaData] = useState([]);
  const [filteredFlotaData, setFilteredFlotalData] = useState([]); 
  const [fishermanData, setFishermanData] = useState([]);
  const [filteredFishermanData, setFilteredFishermanData] = useState([]); 
  const [especie, setEspecie] = useState(''); 
  const [year, setYear] = useState(''); 
  const [eslora, setEslora] = useState(''); 
  const [iaState, setIaState] = useState(false); 
  const [caletaTableInfo, setCaletaTableInfo] = useState([]); 
  const [isVisibleIA, setIsVisibleIA] = useState(false);
  const [responseIA, setResponseIA] = useState('');
  const {setIsLoader, } = useContext(GlobalDataContext);  // Usa el contexto correcto
  const [selectedLetter, setSelectedLetter] = useState('0');
  const [loadingData, setLoadingData] = useState(false);
  


// Contexto
useEffect(() => {
  if (flota !== '') {
    setIsLoader(true); // Activa el estado de carga
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/flota?flota=${flota}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const { flotaCapturesData, flotaBoatsData, flotafishermanData } = jsonData;
        console.log(jsonData)
        setGeneralData(flotaCapturesData);
        setFilteredGeneralData(flotaCapturesData);
        setFlotaData(flotaBoatsData);
        setFilteredFlotalData(flotaBoatsData);
        setFishermanData(flotafishermanData);
        setFilteredFishermanData(flotafishermanData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoader(false); // Desactiva el estado de carga al terminar
     
      }
    };
    fetchData();
  }
}, [flota, setIsLoader]);



  // Aplicación de filtros
  useEffect(() => {
    if (flota !== '') {

      const applyFilters = () => {
        let filteredData = Array.isArray(generalData) ? [...generalData] : [];
        let filteredEmbarcaciones = Array.isArray(flotaData) ? [...flotaData] : [];
        let filteredPescadores= Array.isArray(fishermanData) ? [...fishermanData] : [];    
       
        if (flota !== '') {
          filteredData = filteredData.filter(item => item.flota === flota);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.flota === flota);
          filteredPescadores = filteredPescadores.filter(item => item.flota === flota);
        }
       
        if (caleta !== '') {
          filteredData = filteredData.filter(item => item.caleta === caleta);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.puerto_caleta === caleta);
          filteredPescadores = filteredPescadores.filter(item => item.puerto_caleta === caleta);
        }
        if (region !== '') {
          filteredData = filteredData.filter(item => item.region_desembarque === region);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.region_desembarque === region);
          filteredPescadores = filteredPescadores.filter(item => item.region_desembarque === region);
        }
        if (year !== '') {
          filteredData = filteredData.filter(item => item.year === year);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.year === year);
          filteredPescadores = filteredPescadores.filter(item => item.year === year);
        }
        if (eslora !== '') {
          filteredData = filteredData.filter(item => item.categoria_eslora === eslora);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.categoria_eslora=== eslora);
          filteredPescadores = filteredPescadores.filter(item => item.categoria_eslora === eslora);
        }
        if (filteredData.length === 0) {
          setFilteredGeneralData([
            {
              "caleta":caleta,
              "categoria_eslora": eslora,
              "desembarque": 0.0000001,
              "especie":especie,
              "flota":flota,
              "nombre_region": region,
              "region_desembarque": region,
              "year": 0
  
            }
          ]);
        } else {
          setFilteredGeneralData(filteredData);
        }

        if (filteredEmbarcaciones.length === 0) {
          setFilteredFlotalData([]);
        } else {
          setFilteredFlotalData(filteredEmbarcaciones);
        }

               if (filteredPescadores.length === 0) {
                setFilteredFishermanData([]);
        } else {
          setFilteredFishermanData(filteredPescadores);
        }
       



      };



      applyFilters();
    }
  }, [region, flota, caleta, especie, year, eslora, generalData, flotaData, fishermanData]);

  return (
    <FilterDataContext.Provider value={{
      region, setRegion,
      caleta, setCaleta,
      flota, setFlota,
      generalData, filteredGeneralData,
      flotaData, filteredFlotaData,
      fishermanData, filteredFishermanData,
      especie, setEspecie,
      year, setYear,
      eslora, setEslora,
      iaState, setIaState,
      caletaTableInfo, 
      setCaletaTableInfo,
      isVisibleIA, 
      setIsVisibleIA,
      responseIA, 
      setResponseIA,
      selectedLetter, 
      setSelectedLetter,
      loadingData, 
      setLoadingData
    }}>
      {props.children}
    </FilterDataContext.Provider>
  );
};

export default DataProviderFilter;
