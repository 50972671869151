import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { ZoneDataContext } from "../../../../contexts/DataProviderZone";
import "./ChartCircleCaletaZoneStyle.css";

export class ChartCircleCaletaZone extends Component {
  static contextType = ZoneDataContext;


  getHeaderTitle(zone, flota) {
    return zone === "" && flota === ""
      ? `Desembarque por caleta país`
      : zone === "" && flota !== ""
      ? `Desembarque por caleta ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`
      : zone !== "" &&
        zone !== '160' &&
        zone !== '9999' &&
        zone !== '0' &&
        flota === ""
      ? `Desembarque por caleta de la Zona ${zone}`
      : zone !== "" && zone === '160' && flota === ""
      ? `Desembarque por caleta Zona pesca aguas internacionales`
      : zone !== "" && zone === '9999' && flota === ""
      ? `Desembarque por caleta Zona Pesca de recolectores`
      : zone !== "" && zone === '0' && flota === ""
      ? `Desembarque por caleta Zona Pesca sin Identificar`
      : zone !== "" &&
        zone !== 999 &&
        zone !== 99 &&
        flota !== ""
      ? `Desembarque por caleta ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Zona ${zone}`
      : zone !== "" && zone === 999 && flota !== ""
      ? `Desembarque por caleta ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero`
      : zone !== "" && zone === 99 && flota !== ""
      ? `Desembarque por caleta ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras`
      : `Sin información disponible`;
  }
  

  handleChartClick = (params) => {
    const { setCaleta, caleta } = this.context;

    if (params.name !== caleta) {
      setCaleta(params.name);
    } else {
      setCaleta("");
    }
  };

  render() {
    const { filteredGeneralData, zone, flota, eslora } = this.context;
    
    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por caleta
    const desembarquePorCaleta = {};

    // Calcular el total de desembarque para cada caleta
    filteredGeneralData.forEach((item) => {
      if (!desembarquePorCaleta[item.caleta]) {
        desembarquePorCaleta[item.caleta] = 0;
      }
      desembarquePorCaleta[item.caleta] += item.desembarque;
    });

    // Preparar datos para el gráfico, ordenados de mayor a menor
    const data = Object.keys(desembarquePorCaleta)
      .map((caleta) => ({
        name: caleta,
        value: desembarquePorCaleta[caleta],
      }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

    const option = {
      color: [
        "#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463",
        "ORANGE",
        "#2980B9",
        "#C70039",
        "#5DADE2",
        "#900C3F",
        "#117864",
        "#581845",
        "#FF5733",
        "#FFC300",
        "#DAF7A6",
        "#1F618D",
        "#2874A6",
        "#D98880",
        "#AF7AC5",
        "#D4AC0D",
        "#1ABC9C",
        "#3498DB",
        "#E74C3C",
        "#2E86C1",
        "#A93226",
        "#F39C12",
        "#C0392B",
        "#27AE60",
        "#F4D03F",
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false, // Oculta la leyenda
      },
      series: [
        {
          name: "Desembarque",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-caleta-region">
        <div className="title-text-chart-caleta">
          {this.getHeaderTitle(zone, flota, eslora)}
        </div>

        <ReactECharts
          className="chart-general-caleta-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleCaletaZone;
