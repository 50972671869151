import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Typography, Button } from "@mui/material";

const FilterCarousel = () => {
  const items = [
    {
      title: "Filtro 1",
      content: "Este es el contenido del filtro 1.",
    },
    {
      title: "Filtro 2",
      content: "Este es el contenido del filtro 2.",
    },
    {
      title: "Filtro 3",
      content: "Este es el contenido del filtro 3.",
    },
  ];

  return (
    <Box sx={{ width: "100%", minWidth: 400, margin: "auto", textAlign: "center" }}>
      <Carousel
        indicators={true}
        navButtonsAlwaysVisible
        autoPlay={false}
        animation="slide"
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              p: 3,
              border: "1px solid #ddd",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography variant="h6" gutterBottom>
              {item.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {item.content}
            </Typography>
            <Button variant="contained">Acción {index + 1}</Button>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default FilterCarousel;
