import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { isMobile } from 'react-device-detect';
import './MapZoneStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { ZoneDataContext } from '../../../contexts/DataProviderZone';

export default class MapZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      selectedZone: '',
    };
  }

  static contextType = ZoneDataContext;

  componentDidMount() {
    const chileGeoJson = require('../../../data/regionesZones.json');
    echarts.registerMap('Chile', chileGeoJson);
    this.setState({ mapLoaded: true });
  }

  handleZoneClick = (params) => {
    const { zoneScreenZone, setZone } = this.context;
    const zoneName = params.name;
    const selectable = params.data ? params.data.selectable : false;

    // Si no es seleccionable, no hacer nada
    if (!selectable) return;

    // Actualizar la zona seleccionada en el estado y en el contexto
    this.setState((prevState) => ({
      selectedZone: prevState.selectedZone === zoneName ? '' : zoneName,
    }));

    if (isMobile) {
      setZone(zoneName);
    } else {
      if (zoneName !== zoneScreenZone) {
        setZone(zoneName);
      } else {
        setZone('');
      }
    }
  };

  render() {
    const { mapLoaded, selectedZone } = this.state;

    if (!mapLoaded) {
      return <div>No hay datos disponibles.</div>;
    }

    const chileGeoJson = require('../../../data/regionesZones.json');

    // Definir una lista de 16 colores contrastantes para las zonas no seleccionables
    const colorPalette = [
 '#FF4500','#846f2d', '#32CD32', '#8A2BE2', '#FFD700', '#DC143C', '#00BFFF', '#FF1493',
      '#ADFF2F', '#8B0000', '#B0E0E6', '#C71585', '#FF8C00', '#2E8B57', '#D2691E', '#00CED1'
    ];

    // Asignar un color a cada zona no seleccionable de forma cíclica
    const mapData = chileGeoJson.features.map((region, index) => ({
      name: region.properties.name,
      value: selectedZone === region.properties.name ? 1 : 0,
      selectable: region.selectable,
      itemStyle: {
        areaColor: region.selectable
          ? '#87CEFA' // Color base para las regiones seleccionables
          : colorPalette[index % colorPalette.length], // Colores cíclicos para las zonas no seleccionables
      },
      emphasis: {
        itemStyle: region.selectable
          ? { areaColor: 'red' } // Color de énfasis para el hover de zonas seleccionables
          : { areaColor: '#D3D3D3' },
      },
      selectedMode: false,  // Deshabilitar la selección visual (evita el color amarillo)
      attributes: {
        'data-selectable': region.selectable ? 'true' : 'false',
      },
    }));

    // Configuración de ECharts
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          // Solo mostrar el nombre de la zona si es seleccionable
          return params.data.selectable ? `${params.name}` : ''; // Si no es seleccionable, no muestra nada
        },
      },
      series: [
        {
          name: 'Regiones',
          type: 'map',
          roam: false,
          map: 'Chile',
          data: mapData, // Pasar los datos procesados con estilos dinámicos
          emphasis: {
            itemStyle: {
              areaColor: '#ADD8E6', // Color de énfasis cuando se pasa el mouse (para zonas seleccionables)
            },
            label: {
              show: true, // Mostrar nombre en hover
            },
          },
          selectedMode: 'none', // Deshabilitar la selección de zonas, pero mantiene el hover
          itemStyle: {
            areaColor: '#87CEFA',  // Color predeterminado para las zonas
          },
        },
      ],
    };

    return (
      <div className="container-map-zone">
        <h2 className="title-map">Zonas de Pesca</h2>
        <ReactECharts
          className="map-zone"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{
            click: (params) => {
              // Evitar cualquier cambio de color al hacer clic
              this.handleZoneClick(params);
            },
          }}
        />

        <div
          className={
            selectedZone === 'Aguas internacionales'
              ? 'select-container-other-region'
              : 'container-other-region'
          }
          onClick={() =>
            this.handleZoneClick({ name: '160', data: { selectable: true} })
          }
        >
          <FontAwesomeIcon className="logo-other-region" icon={faLocationDot} />
          <h4 className="text-other-region">Aguas Internacionales</h4>
        </div>

        <div
          className={
            selectedZone === 'Sin Identificar'
              ? 'select-container-other-region'
              : 'container-other-region'
          }
          onClick={() =>
            this.handleZoneClick({ name: '0', data: { selectable: true} })
          }
        >
          <FontAwesomeIcon className="logo-other-region" icon={faLocationDot} />
          <h4 className="text-other-region">Zona sin Identificar</h4>
        </div>

        
        <div
          className={
            selectedZone === 'Recolectores'
              ? 'select-container-other-region'
              : 'container-other-region'
          }
          onClick={() =>
            this.handleZoneClick({ name: '9999', data: { selectable: true} })
          }
        >
          <FontAwesomeIcon className="logo-other-region" icon={faLocationDot} />
          <h4 className="text-other-region">Recolectores</h4>
        </div>
      </div>
    );
  }
}
