import React, { useContext } from "react";
import "./PlantScreenStyle.css";
import Loading from "../../components/Loading/Loading";
import { GlobalDataContext } from "../../contexts/DataProvider";
import FloatingMenuPlant from "../../components/PlantScreenComponent/FloatingMenuPlant/FloatingMenuPlant";
import TablePlantYear from "../../components/PlantScreenComponent/Tables/TablePlantYear/TablePlantYear";
import { ChartLineYearPlant } from "../../components/PlantScreenComponent/ChartPlant/ChartLineYearPlant/ChartLineYearPlant";
import TablePlantEspecie from "../../components/PlantScreenComponent/Tables/TablePlantYearEspecie/TablePlantYearEspecie";
import TablePlantReprocess from "../../components/PlantScreenComponent/Tables/TablePlantReprocess/TablePlantReprocess";
import TablePlantRegion from "../../components/PlantScreenComponent/Tables/TablePlantRegion/TablePlantRegion";
export default function PlantScreen() {
  const { isLoader } = useContext(GlobalDataContext);

  return (
    <div className="container-plant-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <>
          <FloatingMenuPlant />

          <div className="container-plant-info">
            <div className="container-plant-1">
              <TablePlantYear />
              <ChartLineYearPlant />
            </div>
            <div className="container-plant-2">
              <TablePlantEspecie />
              <TablePlantReprocess />
            </div>
            <div className="container-plant-3">
              <TablePlantRegion />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
